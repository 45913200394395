import React from "react"
import Layout from "../components/Layout"
import Container from "../components/Container"

const TravelPage = () => (
  <Layout title="Travel">
    <Container pad>
      <br />
      I'm workin' on it okay???
      <br />- Rick
    </Container>
  </Layout>
)

export default TravelPage
